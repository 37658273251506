import {Content, Section, Box, Panel, Card, Header, Body, Footer, Text, Button, Icon, Form} from 'crack-ux'; 
import {useNavigate} from 'react-router-dom';

// Data
import ServicesData from '../../data/Services.json'; 

// Style
import './style.css';

const Init = () =>
{
	return (
		<Section class='init relative' style={{height: 'calc(100vh - var(--header))'}}>
			<Content class='laptop'>
				<Box class='full_center center width_tablet'>
					<Header class='no_border'>
						<Text class='white bold font_family_2 uppercase bold' size={36} style={{lineHeight: 1}}>We Provide Digital Services</Text>
					</Header>
					<Body>
						<Text class='light_gray block' size={18}>We take care of the technical and digital side,</Text>
						<Text class='light_gray block' size={18}>you of the business and the clients.</Text>
						<Text class='light_gray block' size={18}>We want to support you in your growth.</Text>
					</Body>
					<Footer class='no_border'>
						<Button class='background_primary white' round={25} text='Services'/>
						<Button class='background_secondary white margin_left_m' round={25} text='Products'/>
					</Footer>
				</Box>
			</Content>
		</Section>
	)
}

const AboutUs = () =>
{
	return (
		<Section style={{paddingTop: 100}}>
			<Content class='laptop' style={{height: 'auto'}}>
				<Header class='no_border'>
					<Text class='block font_family_2 uppercase bold' size={36}>About Us</Text>
				</Header>
				<Body>
					<Panel class='desktop7 no_padding_vertical no_padding_left' style={{paddingRight: 50}}>
						<Text class='block justify' style={{marginBottom: 15}}>Crack SRL is a company founded in 2016 that works in the area of software development, information technology, app development and digital marketing.</Text>
						<Text class='block justify' style={{marginBottom: 15}}>We currently have the trust of clients in Australia, Costa Rica and Bolivia, thanks to the professionals that make up the Crack team, with more than 12 years of experience and the values that we demonstrate as a company.</Text>
						<Text class='block justify' style={{marginBottom: 15}}>Our philosophy is based on the concept of serving and always doing the right thing to live with honor. Following this line we seek to support entrepreneurship, startups and established companies, with our technical skills.</Text>
						<Text class='block justify' style={{marginBottom: 15}}>If you want to know more about our <Text class='blue bold pointer'>services</Text> and <Text  class='blue bold pointer'>products</Text>, we invite you to see the respective sections and if you want to schedule an appointment with us, click on the button below.</Text>
						<Section style={{marginTop: 25}}>
							<Button class='background_primary white' text='Schedule a Meeting'/>
						</Section>
					</Panel>
					<Panel class='desktop5 no_padding'>
						<img src='/assets/about-us.jpg' alt='About Us'/>
					</Panel>
				</Body>
			</Content>
		</Section>
	)
}

const Services = () =>
{
	const Service = ({icon, title, description, route}) =>
	{
		const navigate = useNavigate();

		return (
			<Panel class='desktop4'>
				<Card>
					<Header>
						{typeof icon === 'string' ? <Text size={18}>{icon} {title}</Text> : <>{icon}<Text size={18}>{title}</Text></>} 
					</Header>
					<Body style={{height: 100}}>
						<Text class='gray'>{description}</Text>
					</Body>
					<Footer class='right'>
						<Button text='Read More' Click={() => navigate(route)}/>
					</Footer>
				</Card>
			</Panel>
		)
	}

	return (
		<Section style={{paddingTop: 100, paddingBottom: 100}}>
			<Content class='laptop' style={{height: 'auto'}}>
				<Header class='no_border'>
					<Text class='block font_family_2 uppercase bold' size={36}>Services</Text>
				</Header>
				<Body class='no_padding'>
					{ServicesData.map((service, index) => <Service key={index} {...service}/>)}
				</Body>
			</Content>
		</Section>
	)
}

// const Testimonials = () =>
// {
// 	return (
// 		<Section class='background_primary' style={{paddingTop: 50, paddingBottom: 50}}>
// 			<Content class='laptop' style={{height: 'auto'}}>
// 				<Header class='no_border center'>
// 					<Text class='block font_family_2 white' size={36}>Testimonials</Text>
// 				</Header>
// 				<Body>
// 					<Header class='no_border'>

// 					</Header>
// 					<Body>

// 					</Body>
// 				</Body>
// 			</Content>
// 		</Section>
// 	)
// }

const Products = () =>
{
	const products =
	[
		{
			button:
			{
				class: 'background_blue white',
			},
			title: 'Sice CRM and ERP',
			description: 'We develop websites and web applications that are responsive and adaptable to any device.',
			link: 'https://sice.cracksrl.com',
		},
		{
			button:
			{
				class: 'background_purple white',
			},
			title: 'Crack UX Library',
			description: 'We develop desktop applications that are responsive and adaptable to any device.',
			link: 'https://ux.cracksrl.com',
		},
	]

	const Product = ({title, description, link, button}) =>
	{
		return (
			<Panel class='desktop4'>
				<Card>
					<Header>
						<Text size={18}>{title}</Text> 
					</Header>
					<Body style={{height: 100}}>
						<Text class='gray'>{description}</Text>
					</Body>
					<Footer class='right'>
						<a href={link} target='_blank' rel='noreferrer'>
							<Button {...button}>
								<Text>Go to webpage</Text>
								<Icon class='icon-link-ext float_right' style={{marginTop: 5, marginLeft: 10}}/>
							</Button>
						</a>
					</Footer>
				</Card>
			</Panel>
		)
	}

	return (
		<Section style={{paddingTop: 100, paddingBottom: 100}}>
			<Content class='laptop' style={{height: 'auto'}}>
				<Header class='no_border'>
					<Text class='block font_family_2 uppercase bold' size={36}>Products</Text>
				</Header>
				<Body class='no_padding'>
					{products.map((product, index) => <Product key={index} {...product}/>)}
				</Body>
			</Content>
		</Section>
	)
}

const ContactUs = () =>
{
	return (
		<Section class='background_secondary' style={{paddingTop: 50, paddingBottom: 50}}>
			<Content class='laptop center' style={{height: 'auto'}}>
				<Header class='no_border'>
					<Text class='block font_family_2 white uppercase bold' size={36}>Contact Us</Text>
				</Header>
				<Body>
					<Panel class='desktop6'>
						<Card>
							<Form
								Header = {<Text>Write us any question or doubt you may have and we will contact you as soon as possible.</Text>}
								inputs =
								{[
									{
										class: 'desktop6 no_padding_vertical no_padding_left',
										text: 'Name',
									},
									{
										class: 'desktop6 no_padding_vertical no_padding_right',
										text: 'Last Name',
									},
									{
										text: 'Email',
									},
									{
										text: 'Message',
										type: 'textarea',
										rows: 10
									},
								]}
							/>
						</Card>
					</Panel>
				</Body>
			</Content>
		</Section>
	)
}

const EndPage = () =>
{
	return (
		<Section class='end_page center'>
			<Footer class='no_border'>
				<Text class='light_gray'>Crack SRL - 2022</Text>
			</Footer>
		</Section>
	)
}

const Home = () =>
{
	return (
		<Content class='page_home no_padding vertical_scroll'>
			<Init/>
			<AboutUs/>
			<Services/>
			{/* <Testimonials/> */}
			<Products/>
			<ContactUs/>
			<EndPage/>
		</Content>
	)
}

export default Home;