import {Routes, Route, Navigate, useNavigate} from "react-router-dom";
import {Page, Section, Box, Header, Body, Button} from "crack-ux";

// Pages
import Home from "../../pages/Home";
import Service from "../../pages/Service";

const Menu = () =>
{
	const navigate = useNavigate();

	const options =
	[
		{
			text: 'Home',
			route: '/',
		},
		{
			text: 'About Us',
			route: '/',
		},
		{
			text: 'Services',
			route: '/',
		},
		{
			text: 'Products',
			route: '/',
		},
		{
			text: 'Contact',
			route: '/',
		},
	]

	const Option = ({text, route}) =>
	{
		return (
			<Button class='link' text={text} Click={() => navigate(route)} style={{marginLeft: 15}}/>
		)
	}

	return (
		<Section class='right'>
			<Box class='float_left' style={{marginTop: -3}}>
				<img className='pointer' src="/assets/crack-text.png" width={100} onClick={() => navigate('/')} alt='Crack Logo Text'/>
			</Box>
			<Box>
				{options.map((option, index) => <Option key={index} {...option}/>)}
			</Box>
		</Section>
	)
}

const CrackPage = () =>
{
	return (
		<Page>
			<Header class='no_border shadow'>
				<Menu/>
			</Header>
			<Body class='no_padding'>
				<Routes>
					<Route path="/services/:page" exact={true} element={<Service/>}/>
					<Route path="/services" exact={true} element={<Service/>}/>
					<Route path="/" exact={true} element={<Home/>}/>
					<Route path="*" element={<Navigate to='/'/>}/>			
				</Routes>
			</Body>
		</Page>
	)
}

export default CrackPage;