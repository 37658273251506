import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";

// Layouts
import Page from "./layouts/Page";

// External
import './external/fontello/css/crack.css';
import './external/fontello/css/animation.css';

// Styles
import "crack-ux/style/General.css";
import "crack-ux/style/themes/Business.css";
import "./General.css";

const CrackRoutes = () =>
{
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/*" exact={true} element={<Page/>}/>
				<Route path="*" exact={true} element={<Navigate to='/'/>}/>			
			</Routes>
		</BrowserRouter>
	)
}

export default CrackRoutes;