import {useParams, useNavigate} from 'react-router-dom';
import {Content, Section, Panel, Card, Header, Body, Footer, Text, Icon, Button, List} from 'crack-ux';

// Data
import ServicesData from '../../data/Services.json';

// Style
import './style.css';

const ServicesList = () =>
{
	const navigate = useNavigate();

	return (
		<Panel>
			<Header>
				<Text class='bold uppercase' size={18}>Services</Text>
			</Header>
			<Body class='no_padding'>
				<List
					options = {ServicesData.map(service => ({text: service.title, Click: () => navigate(service.route)}))}
				/>
			</Body>
		</Panel>
	)
}

const Service = () =>
{
	const params = useParams();

	const service = ServicesData.find(service => service.page === params.page);

	return (
		<Content class='page_service laptop vertical_scroll'>
			<Panel class='desktop9'>
				<img src={service.cover} alt={service.page}/>
			</Panel>
			<Panel class='desktop3 no_padding'>
				<ServicesList/>
			</Panel>
		</Content>
	)
}

export default Service;